import React from 'react'
import {Link} from 'gatsby'

import styles from './sucesso.module.css'

function Index () {
  return (
    <div className={styles.root}>
      <h1>Mensagem enviada com sucesso!</h1>
      <p className={styles.backlink}><Link to="/">Ir para a página inicial</Link></p>
    </div>
  )
}

export default Index